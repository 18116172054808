import React from 'react';
import { graphql } from 'gatsby';

import BannerSimple from '@components/BannerSimple';
import ContactForm from '@components/ContactForm';
import Content from '@components/Content';
import ContentColumns from '@components/ContentColumns';
import ContentNarrow from '@components/ContentNarrow';
import ContentWithImage from '@components/ContentWithImage';
import HeroHomepage from '@components/HeroHomepage';
import HeroSubPage from '@components/HeroSubpage';
import LocationsByGroup from '@components/LocationsByGroup';
import LocationsMap from '@components/LocationsMap';

import { isBlockActive } from '@utils';

const BlocksRenderer = ({ pageProps, pageData }) => {
  const { _rawBlocks, blocks } = pageData;

  return (
    <>
      {blocks && (
        <>
          {blocks.map((block, i) => {
            // Check that the block has a type
            if (block && block._type) {
              const type = block._type;

              let rawData = _rawBlocks || '';
              rawData = rawData[Object.keys(rawData)[i]];

              const blockProps = {
                data: block,
                rawData,
                pageProps,
                pageData,
                key: block._key,
              };

              if (type === 'bannerSimple' && isBlockActive(block)) {
                return <BannerSimple {...blockProps} />;
              }
              if (type === 'contactForm' && isBlockActive(block)) {
                return <ContactForm {...blockProps} />;
              }
              if (type === 'contentNarrow' && isBlockActive(block)) {
                return <ContentNarrow {...blockProps} />;
              }
              if (type === 'content' && isBlockActive(block)) {
                return <Content {...blockProps} />;
              }
              if (type === 'contentWithImage' && isBlockActive(block)) {
                return <ContentWithImage {...blockProps} />;
              }
              if (type === 'contentColumns' && isBlockActive(block)) {
                return <ContentColumns {...blockProps} />;
              }
              if (type === 'heroHomepage' && isBlockActive(block)) {
                return <HeroHomepage {...blockProps} />;
              }
              if (type === 'heroSubPage' && isBlockActive(block)) {
                return <HeroSubPage {...blockProps} />;
              }
              if (type === 'locationsByGroup' && isBlockActive(block)) {
                return <LocationsByGroup {...blockProps} />;
              }
              if (type === 'locationsMap' && isBlockActive(block)) {
                return <LocationsMap {...blockProps} />;
              }
            }
            return null;
          })}
          {/* <ContactForm /> */}
        </>
      )}
    </>
  );
};

export default BlocksRenderer;

export const GlobalBlockSettingsFragment = graphql`
  fragment GlobalBlockSettingsFragment on SanityGlobalBlockSettings {
    isActive
  }
`;

// These no work right 😕
// export const PageInfoFragment = graphql`
//   fragment PageInfoFragment on SanityPageInfo {
//     name
//     slug {
//       current
//     }
//   }
// `;

// export const PageInfoReadOnlyFragment = graphql`
//   fragment PageInfoReadOnlyFragment on SanityPageInfoReadOnly {
//     name
//     slug {
//       current
//     }
//   }
// `;
