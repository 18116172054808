import React from 'react';
import { graphql } from 'gatsby';

import BlockContent from '@components/BlockContent';
import ListWithIcons from '@components/ListWithIcons';

import { Container, Image } from '@elements';

import { evenOrOdd } from '@utils/helpers';

const ContentColumns = ({ data, rawData, pageProps, pageData }) => {
  let numberOfThings = 0;

  // Loop through all the items to count how many of each type there are.  TODO: Do this with array methods
  if (data && data.columns && data.columns.length > 0) {
    data.columns.forEach(column => {
      if (column && column._type) {
        if (column._type === 'figureWithOptions') {
          numberOfThings += 1;
        }
        if (column._type === 'contentBox') {
          numberOfThings += 1;
        }
        if (column._type === 'list') {
          numberOfThings += 1;
        }
      }
    });
  }

  return (
    <>
      <Container className="">
        <div
          className={`grid gap-8 lg:gap-10 sm:grid-cols-${
            // eslint-disable-next-line no-nested-ternary
            evenOrOdd(numberOfThings) === 'even' ? '2' : '1'
          } lg:grid-cols-${
            data && data.maxColumns < numberOfThings
              ? data.maxColumns
              : numberOfThings
          } ${data.centerVertically && 'items-center'}`}
        >
          {data &&
            data.columns &&
            data.columns.map((column, i) => (
              <React.Fragment key={column._key}>
                {column && column._type === 'figureWithOptions' && (
                  <div className="self-center">
                    <Image image={column} />
                  </div>
                )}

                {column &&
                  column._type === 'contentBox' &&
                  rawData &&
                  rawData.columns &&
                  rawData.columns[i] &&
                  rawData.columns[i].text && (
                    <BlockContent
                      key={column._key}
                      blocks={rawData.columns[i].text || []}
                      className=""
                    />
                  )}

                {column && column._type === 'list' && (
                  <ListWithIcons items={rawData.columns[i].listItems} />
                )}
              </React.Fragment>
            ))}
        </div>
      </Container>
    </>
  );
};

export default ContentColumns;

export const ContentColumnsFragment = graphql`
  fragment ContentColumnsFragment on SanityContentColumns {
    _type
    _key
    centerVertically
    columns {
      ... on SanityContentBox {
        _key
        _type
      }
      ... on SanityFigureWithOptions {
        _key
        _type
        asset {
          fluid(maxWidth: 1600) {
            ...GatsbySanityImageFluid
          }
        }
        alt
        caption
        shadow
        roundedCorners
      }
      ... on SanityList {
        _key
        _type
        listItems {
          heading
          icon {
            iconImage {
              alt
              asset {
                url
              }
            }
            color
          }
        }
      }
    }
    globalBlockSettings {
      ...GlobalBlockSettingsFragment
    }
    maxColumns
  }
`;
