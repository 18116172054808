import React from 'react';
import { graphql } from 'gatsby';

import BlockContent from '@components/BlockContent';

import { Container } from '@elements';

const ContentNarrow = ({ rawData }) => (
  <Container className="lg:max-w-3xl">
    <BlockContent blocks={rawData.text || []} />
  </Container>
);

export default ContentNarrow;

export const ContentNarrowFragment = graphql`
  fragment ContentNarrowFragment on SanityContentNarrow {
    _key
    _type
    #...GlobalBlockSettingsFragment
    globalBlockSettings {
      ...GlobalBlockSettingsFragment
    }
  }
`;
