import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql, Link as GatsbyLink } from 'gatsby';

import BlockContent from '@components/BlockContent';
import { Container } from '@elements';
import { createLocationSlug, lineBreakToBr } from '@utils';

const LocationsByGroup = () => {
  const locationsByGroupQuery = useStaticQuery(graphql`
    query LocationsByGroupQuery {
      allSanityLocation(
        sort: { fields: group____id, order: DESC }
        filter: { isActive: { ne: false } }
      ) {
        edges {
          node {
            geopoint {
              _type
              alt
              lat
              lng
              _key
            }
            id
            isActive
            address
            city
            hours
            state
            zip
            group {
              id
              _rawDescription(resolveReferences: { maxDepth: 10 })
              pageInfo {
                name
                slug {
                  current
                }
              }
            }
            phone
            pageInfo {
              name
              slug {
                current
              }
            }
          }
          previous {
            group {
              id
            }
          }
        }
      }
    }
  `);

  const locations = locationsByGroupQuery.allSanityLocation.edges;

  return (
    <section className="color-mode-background-200">
      <Container>
        <div id="locations">
          <span className="sr-only">Map Section</span>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {locations.map((edge, i) => {
            console.log('locations:', locations);
            const prev = edge.previous;
            const location = edge.node;

            return (
              <React.Fragment key={location.id}>
                {(prev === null || prev.group.id !== location.group.id) && (
                  <div
                    className={`grid-cols-1 md:col-span-2 ${
                      i !== 0 ? 'mt-10' : 'mt-0'
                    }`}
                  >
                    <GroupHeading id={location.group.pageInfo.slug.current}>
                      <span className="text-primary">
                        {location.group.pageInfo.name || 'Area'}
                      </span>{' '}
                      Locations
                    </GroupHeading>
                    {location.group._rawDescription && (
                      <BlockContent
                        blocks={location.group._rawDescription || []}
                      />
                    )}
                  </div>
                )}
                <div
                  key={location.id}
                  className="color-mode-background shadow-lg "
                >
                  <div className="p-4 sm:px-6 flex md:flex-col lg:flex-row items-center justify-between border-b flex-wrap sm:flex-no-wrap">
                    <div className="">
                      <h3 className="text-lg leading-6 font-medium mb-0">
                        {location.pageInfo.name && location.pageInfo.name}
                      </h3>
                    </div>
                    <div className="pt-4 lg:p-0 flex-shrink-0 flex">
                      {location.phone &&
                        location.phone.replace(/\D/g, '') !== '' && (
                          <span className="lg:ml-3 inline-flex rounded-md shadow-sm">
                            <a
                              href={`tel:${location.phone.replace(/\D/g, '')}`}
                              className="relative inline-flex btn btn-ghost"
                            >
                              <svg
                                className="-ml-1 mr-2 h-5 w-5"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                              >
                                <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                              </svg>
                              <span>Call</span>
                            </a>
                          </span>
                        )}
                      {location.pageInfo.slug.current && (
                        <span className="ml-3 inline-flex rounded-md shadow-sm">
                          <GatsbyLink
                            to={`/${createLocationSlug(location)}`}
                            className="relative inline-flex btn btn-primary"
                          >
                            View Page
                          </GatsbyLink>
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="p-4">
                    <div className="flex items-start">
                      <div className=" w-0 flex-1 pt-0.5">
                        {location.pageInfo.name && (
                          <p className="text-sm leading-5 font-medium text-gray-900">
                            {location.pageInfo.name}
                          </p>
                        )}
                        <p className="mt-1 text-sm leading-5">
                          {location.address && location.address}
                          <br />
                          {location.city && location.city},{' '}
                          {location.state && location.state}.{' '}
                          {location.zip && location.zip}
                        </p>
                        {location.phone && (
                          <p className="mt-1 text-sm leading-5">
                            <strong>Phone:</strong> {location.phone}
                          </p>
                        )}
                        {location.hours && (
                          <p className="mt-1 text-sm leading-5">
                            <strong>Hours:</strong>
                            <br />
                            {lineBreakToBr(location.hours)}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            );
          })}
        </div>
      </Container>
    </section>
  );
};

export default LocationsByGroup;

const GroupHeading = styled.h2`
  margin-top: 4rem;
  &:before {
    display: block;
    height: 6rem;
    margin-top: -6rem;
    visibility: hidden;
    content: '';
  }
`;

export const LocationsByGroupFragment = graphql`
  fragment LocationsByGroupFragment on SanityLocationsByGroup {
    _key
    _type
    globalBlockSettings {
      ...GlobalBlockSettingsFragment
    }
  }
`;
